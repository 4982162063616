import { createRouter, createWebHistory } from 'vue-router'
import {getNavigatonItems} from '@/static/nav'


const routes:any = []

getNavigatonItems().map(x=>{
  routes.push({
    ...x,
    component: ()=>import('../views/'+x.component)
  })
})

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
