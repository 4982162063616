import { createI18n } from 'vue-i18n'

import { Locales } from "@/models/locales";

import hu from "@/locales/hu.json";
import en from "@/locales/hu.json";

const messages = {
    [Locales.HU]: hu,
    [Locales.EN]: en
};



export const i18n = createI18n({
    locale: Locales.HU,
    fallbackLocale: Locales.HU,
    messages
})