
import { defineComponent } from 'vue';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import {setApiInterceptor} from '@/api'
import Navigation from '@/components/Navigation.vue';
export default defineComponent({
  name: 'App',
  components: {
    Toast,
    Navigation
  },
  setup(){
    const toast = useToast();
    setApiInterceptor(toast);
  }
});
