
import {getNavigatonItems} from '@/static/nav'
import Menubar from 'primevue/menubar';
import { ref } from 'vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Navigation',
  components:{
        Menubar
    },
    setup(){
        const menuItems:any = ref(getNavigatonItems().filter(x=>x.isMenu))
        console.log("ss")
        return {menuItems}
    }
})
