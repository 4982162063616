import axios from 'axios';
import { CompanyMinimal } from "@/models/CompanyMinimal";
import {Item} from '@/models/Item'
import { i18n } from '@/locales';
import { PaperData } from '@/models/PaperData';



const URI = window.location.origin;

const api = axios.create({
    baseURL: URI,
});

export const setApiInterceptor = (toast: any) => {
    api.interceptors.response.use((response) => response, (error:Error) => {
        switch (error.message) {
            case "Network Error":
                toast.add({ severity: 'error', summary: 'Hiba', detail: i18n.global.t('axios.error.network'), life: 3000 });
                break;
        
            default:
                break;
        }
        throw error
    });
}

export const getCompanies = async () => {

    return ((await api.get(`/api/data/company/list`)).data as CompanyMinimal[]).sort((a, b) => a.companyName > b.companyName ? 1 : -1)
}

export const addCompany = async (companyName: string, cui: string) => {
    return await api.post(`/api/data/company/add`, {
        companyName,
        cui,
    })
}

export const updateCompany = async (companyId: number, companyName: string, cui: string) => {
    return await api.post(`/api/data/company/update`, {
        companyId,
        companyName,
        cui,
    })
}

export const savePageData = async (data: any) => {
    return await api.post(`/api/data/page/save`, {
        data
    })
}

export const getPageData = async (pageId:number) => {
    return ((await api.post(`/api/data/page/get`, {
        pageId
    })).data as PaperData)
}

export const getPageDataUtil = async (companyId:number) => {
    return ((await api.post(`/api/data/page/getUtil`, {
        companyId
    })).data as {nrCar:string, curier:string})
}

export const getAllPageData = async () => {
    return ((await api.post(`/api/data/page/getAll`)).data as PaperData[])
}

export const getItemsByCompany = async (companyId: number) => {
    return ((await api.post(`/api/data/page/items`, {
        companyId
    })).data as Item[])
}